.t-container {
    width: 100%;
    padding: 50px 0;
    margin: 50px auto;
    position: relative;
    overflow: hidden;
 }
 
 .t-container:before {
    content: '';
    position: absolute;
    top: 0%;
    left: 10%;
    margin-left: -2.5px;
    width: 3px;
    height: 100%;
    background: #000;
    z-index: 1
 }
 
 .timeline-block {
    width: -webkit-calc(100% + 8px);
    width: -moz-calc(100% + 8px);
    width: calc(90% + 8px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    justify-content: space-between;
    clear: both;
 }
 
 .timeline-block-right {
    float: right;
 }
 
 .timeline-block-left {
    float: left;
    direction: rtl
 }
 
 .marker {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #000;
    background: #000;
    margin-top: 10px;
    z-index: 9999
 }
 .marker-semi {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #000;
    /* background: linear-gradient(#000, #F5F7FA); */
    transform: rotate(45deg);
    background:linear-gradient( -45deg, white, white 50%, white 50%, black 50%, black 50% ); ;
    margin-top: 10px;
    z-index: 9999
    /* transform: rotate(136deg);
    background:linear-gradient( -45deg, black, black 50%, black 50%, white 50%, white 50% ); */
    /* border-radius: 50px;
    border-right-color: black;
    border-top-color: white;
    border-bottom-color: black;
    border-left-color: white;
    border-width: 8px;
    border-style: solid;
    height: 0;
    width: 0;
    margin-top: 10px;
    z-index: 9999 */
 }
 .marker-empty {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #000;
    background: #F5F7FA;
    margin-top: 10px;
    z-index: 9999
 }
 .timeline-content {
    width: 100%;
    padding: 0 15px;
    margin-top: 7px;
    color: black;
 }
 
 .timeline-content h3 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 25px;
    font-weight: 500
 }
 
 .timeline-content span {
    font-size: 13px;
    color: black;
 }
 
 /* .timeline-content p {
    font-size: 14px;
    line-height: 1.5em;
    word-spacing: 1px;
 } */
 .timeline-text{
    font-size: 12px;
    font-weight:normal;
    color:black;
 }
 .tline-text{
    font-size: 12px;
    font-weight:normal;
    color:black;
    margin-top: 5px;
 }
 .time-img{
    width:30px;
    margin-bottom:5px;
 }