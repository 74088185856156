.certificate {
    font-family: 'Lucida Calligraphy New', serif;
    font-size: 40px;
    margin-top: 3%;
}

.certificate-of {
    font-family: 'Lucida Calligraphy New';
    font-size: 15px;
    margin-top: 1%;
}

.certificate-cName {
    /* margin-top: -24%; */
    margin-top: 1rem;
    font-weight: 600;
    font-family: 'Century Schoolbook';
    font-size: 25px;
}

.certificate-completed {
    font-size: 15px;
    font-family: 'Century Schoolbook';
    font-weight: 500;
}

.certificate-on {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Century Schoolbook';
}

.title-and-version {
    font-weight: 500;
    font-family: 'Century Schoolbook';
    font-size: 16px;
}

.century-schoolbook {
    font-family: 'Century Schoolbook';
    font-weight: 500;
}

.weight {
    font-weight: 700;
}


.normal-weight {
    font-weight: 400;
}

.sceem-certificate {
    width: 30%;
    margin-top: -5%;
}

.badge-logo-sceem {
    width: 15%;
    position: absolute;
    bottom: 2%;
    right: 10px;
}

.appreciation-certificate {
    width: 76%;
}