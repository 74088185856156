.events * .fc-col-header-cell {
    background-color: darkslategray !important;
    color: white;
    text-transform: uppercase;
    font-size: larger;
    padding: 6px 0;
}

.events * .fc-col-header-cell.fc-day-sun,
.events * .fc-col-header-cell.fc-day-sat {
    background-color: goldenrod !important;
    color: white
}

.events > .fc-theme-standard td {
    border: none !important;
}

.events * .fc-scrollgrid-liquid {
    border-width: 0px !important;
}

.events * .fc-daygrid-day-frame {
    background-color: #e6e6e6 ;
    border: 3px solid white !important;
    border-radius: 4px;
}

.events * .fc-daygrid-day-events {
    height: 0 !important;
    min-height: 0 !important;
}

.events * .fc-daygrid-day-top {
    height: 3em !important;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: darkslategray;
    font-weight: 700;
}
