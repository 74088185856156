body {
    font-family: Poppins;
    background-color: #EFF2F5;
}

.login-bg {
    background-color: #ffffff !important;
}
.sec-display {
    display: flex;
    grid-auto-rows: 4;
    gap: 3vw;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  
  @keyframes fill {
    0% {
      width: 0%;
      background-color: #FF942C !important;
    }
  
    100% {
      width: 100%;
      background-color: #FF942C !important;
    }
  }
  
  .bg-color {
    margin-top: -0.1px;
    background-color: #FF942C !important;
    animation: fill 10s linear 1;
    height: 100%;
    cursor: pointer;
  }
  
  .sec-color {
    width: 70px;
    height: 8px;
    background-color: gray;
    cursor: pointer;
  }
.error {
    color: red;
    font-size: 9px;
    height: 0px;
    float: left;
}

.tableFixHead {
    overflow-y: auto;
    height: 80vh;
}

.tableFixHead thead th {
    position: sticky;
    top: 0;
    text-align: left;

}

table {
    border-collapse: separate;
    width: 100%;
    padding: 0 4px;
}

#table1 {
    width: 100%;
    padding: 0 4px;
    border-collapse: collapse;
}

#table1 {
    border: 2px solid #5fa1db !important;
}

#table1 th {
    border: 2px solid #5fa1db !important;
}

#table1 td {
    border: 2px solid #5fa1db !important;
}

th,
td {
    padding: 8px 14px;
    border: none;
    font-size: 14px;
}

.tableFixHead th {
    background: #142431;
    color: #EFF2F5;
}

::-webkit-scrollbar {
    display: none;
}

.tr {
    border-radius: 35px;
    border: none;
    box-shadow: 2px 2px 4px #a8a8a8,
        -3px -3px 6px #ffffff;
}

/* td:first-child {
    border-radius: 35px 0 0 35px;
}

td:last-child {
    border-radius: 0 80px 80px 0;
} */

/* .green {
    background: #F6F5FF;
}

.white {
    background: white;
} */

.dropdwon:focus {
    background-color: black !important;
    outline: 2px solid transparent;
    outline-offset: 0;
    border: none;
}

.dropdown-toggle::after {
    display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.t-btn {
    border-radius: 100%;
    border: none;
    box-shadow: 2px 2px 4px #a8a8a8,
        -3px -3px 6px #ffffff;
}

.icon {
    display: flex;
    justify-content: space-between;
}

.icon-w {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    box-shadow: 2px 2px 4px #a8a8a8,
        -3px -3px 6px #ffffff;
    ;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    color: #eff2fe;
    transition: all .5s ease;
}

.icon-t {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    box-shadow: 2px 2px 4px #a8a8a8,
        -3px -3px 6px #ffffff;
    ;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #eff2fe;
    transition: all .5s ease;
}

.star {
    font-size: 38px;
    color: rgb(215, 212, 212);
    cursor: pointer;
    margin: 0.2rem;
}

.star.selected {
    color: black;
}

input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid black;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: black;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid black;
}

#table1 tbody tr:nth-child(odd) {
    background-color: #c3e1f6;
}

#table1 tbody tr:nth-child(even) {
    background-color: transparent;
}

.survey-bg {
    /* background-image: linear-gradient(241deg,#96c8ec 0%, #cfbee0 50%, #eab9ce 100%); */
    background-image: linear-gradient(241deg, #d5e8f7 0%, #f3eff7 50%, #eae4e7 100%);
    opacity: 0.8;
    height: 100vh;
}

/* background-image: linear-gradient(241deg,#d5e6f4 0%, #e7e3ec 50%, #f3e7ec 100%); */
.bottom_bar {
    z-index: 100;
    background-color: black;
    width: 100%;
    height: 55px;
    border-top: 1px solid rgba(48, 25, 124, 0.3);
}

.typing-demo {
    animation: example 2s linear;
}
@keyframes example {
    from {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
}

.blink-text {
    animation: blink 0.5s linear;
}
@keyframes blink {

    0%,
    25%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

.visible {
    opacity: 1;
}