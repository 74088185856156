.rewards {
    list-style: none;
    overflow: hidden;
    font: 18px;
    margin: 1rem 0;
    padding: 0;
    background-color: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.rewards li {
    width: 33.33% !important;
    cursor: pointer;
}

.rewards li span {
    padding: 10px 0 10px 45px;
    background: #142431;
    position: relative;
    display: block;
    flex: 1;
}


.rewards li span:after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid #142431;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 100%;
    z-index: 2;
}

.rewards li span:before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid white;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    margin-left: 1px;
    left: 100%;
    z-index: 1;
}

.rewards li:first-child span {
    padding-left: 10px;
}

.rewards li:nth-child(2) span {
    background: #142431;
}

.rewards li:nth-child(2) span:after {
    border-left-color: #142431;
}

.rewards li:nth-child(3) span {
    background: #142431 !important;
}

.rewards li:nth-child(3) span:after {
    border-left-color: #142431 !important;
}

.rewards li:last-child span:after {
    border: 0;
}

.rewards li span:hover {
    background: #142431;
}

.rewards li span:hover:after {
    border-left-color: #142431 !important;
}

.rewards li:last-child span {
    padding-right: 10px;
}

.striped-table .even {
    background-color: rgb(211, 213, 217)
    /* Even row color */
}

.striped-table .odd {
    background-color: rgb(234, 236, 240);
    ;
    /* Odd row color */
}