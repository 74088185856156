.fc-toolbar-chunk div:first-child {
    display: flex;
}

.fc {
    max-width: 1100px;
    margin: 0 auto;
}

.demo-app-main {
    flex-grow: 1;
}

b {
    margin-right: 3px;
}

.fc-button-group,
.fc-button-primary,
.fc-button-primary:hover,
.fc-button-primary:focus,
.fc-button-primary:focus,
.fc-button-primary:active {
    background-color: #EFF2F5 !important;
    color: black !important;
    border: none !important;
    outline: none !important;
}

.fc-event-main,
.fc-v-event {
    border-radius: 3px;
    outline: none !important;
}

.tooltip .arrow:before {
    border-top-color: #85adad !important;
}

.tooltip .tooltip-inner {
    background-color: #85adad;
    text-align: start;
}
